
// Dependencies
import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './i18n/i18n';  // Initialize i18n

// import { useDispatch } from 'react-redux';

// Bootstrap Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CSS
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import GlobalNav from './components/GlobalNav.js';

import UncaughtBoundary from './errors/UncaughtBoundary.js';

import loadable from '@loadable/component';

import { CONTEXTS } from './store/reducers/appContext.js';

// Views
const HomePage = loadable(() => {
  return import('./views/HomePage.js')
});
const HomePageApp = loadable(() => {
  return import('./views/HomePageApp.js')
}, { ssr: false });
const DataExplorer = loadable(() => import('./views/DataExplorer.js'));
const DefenceExplorer = loadable(() => import('./views/DefenceExplorer.js'));
const PlaceDefenceExplorer = loadable(() => import('./views/PlaceDefenceExplorer.js'));
const FloodNav = loadable(() => import('./views/FloodNav.js'));
const FloodNavHowTo = loadable(() => import('./views/FloodNavHowTo.js'));
const BasinsView = loadable(() => import('./views/BasinsView.js'));
const RiverBasinsView = loadable(() => import('./views/RiverBasinsView.js'));
const ManagementCatchmentsView = loadable(() => import('./views/ManagementCatchmentsView.js'));
const OperationalCatchmentsView = loadable(() => import('./views/OperationalCatchmentsView.js'));
const WaterBodyView = loadable(() => import('./views/WaterBodyView.js'));
const FloodOverviewView = loadable(() => import('./views/FloodOverviewView.js'));
const PrivacyPolicy = loadable(() => import('./views/PrivacyPolicy.js'));
const CopyrightCredits = loadable(() => import('./views/CopyrightCredits.js'));
const DataAccuracy = loadable(() => import('./views/DataAccuracy.js'));
// const StationView = loadable(() => import('./views/StationView.js'));
const SewageSearch = loadable(() => import('./views/SewageSearch.js'));
const SewageSearchPlace = loadable(() => import('./views/SewageSearchPlace.js'));
const StationView = loadable(() => import('./views/StationView.js'));
const StationHistoricalView = loadable(() => import('./views/StationHistoricalView.js'));
const StationHistoricalFloodView = loadable(() => import('./views/StationHistoricalFloodView.js'));
const FloodAlertsSummary = loadable(() => import('./views/FloodAlertsSummary.js'));
const FloodAlertView = loadable(() => import('./views/FloodAlertView.js'));
const View404 = loadable(() => import('./views/View404.js'));
const MetOfficeFGS = loadable(() => import('./views/MetOfficeFGS.js'));
const UKStationMap = loadable(() => import('./views/UKStationMap.js'));
const ManageAppAccount = loadable(() => import('./views/ManageAppAccount.js'), { ssr: false, fallback: null});
// Components
const ApplicationContextInitialiser = loadable(() => import('./components/ApplicationContextInitialiser.js'), { ssr: false, fallback: null });
const AppNetworkStatus = loadable(() => import('./components/AppNetworkStatus.js'), { ssr: false, fallback: null });

// Thunks


//<GlobalNav />
//53.9590, -1.0815
function App({ ssrSubdomain }) {
  const { appContext } = useSelector(state => state.appContext);

  let subdomain = 'www';
  if(ssrSubdomain) {
    subdomain = ssrSubdomain.split('.')[0];
  } else if(typeof window !== 'undefined' && window.location.hostname != 'localhost') {
    subdomain = window.location.hostname.split('.')[0]
  }

  console.log("Render Target: ", subdomain);

  //className="g-0"
  return (
    <div className="App">
          <ApplicationContextInitialiser />
          <GlobalNav />
            <Container fluid  style={{paddingTop: "56px"}}>
              {appContext === CONTEXTS.APP_CONTEXT_XPA ? <AppNetworkStatus /> : null}
              <UncaughtBoundary>
                <Routes>
                  {/* These are for the homepage website */}
                  {subdomain === "www" && appContext !== CONTEXTS.APP_CONTEXT_XPA ? <Route path="/" element={<HomePage />} /> : null}
                  {subdomain === "www" && appContext === CONTEXTS.APP_CONTEXT_XPA ? <Route path="/" element={<HomePageApp />} /> : null}

                  {appContext === CONTEXTS.APP_CONTEXT_XPA ? <Route path="/acc-manage" element={<ManageAppAccount />} /> : null}

                  {/* These are across all endpoints */}
                  <Route path="/copyright-information" element={<CopyrightCredits />} />
                  <Route path="/data-accuracy" element={<DataAccuracy />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                  <Route path="/data-explorer" element={<DataExplorer />} />

                  <Route path="/data-explorer/stationmap/uk" element={<UKStationMap />} />

                  <Route path="/data-explorer/fgs" element={<MetOfficeFGS day="today" />} />
                  <Route path="/data-explorer/fgs/previous" element={<MetOfficeFGS day="previous" />} />

                  <Route path="/data-explorer/defences" element={<DefenceExplorer />} />
                  <Route path="/data-explorer/defences/place/:placeId/:placeName" element={<PlaceDefenceExplorer />} />


                  <Route path="/data-explorer/basins" element={<RiverBasinsView />} />
                  <Route path="/data-explorer/basins/:basinName" element={<BasinsView />} />
                  <Route path="/data-explorer/management-catchments/:managementCatchmentName" element={<ManagementCatchmentsView />} />
                  <Route path="/data-explorer/operational-catchments/:operationalCatchmentName" element={<OperationalCatchmentsView />} />
                  <Route path="/data-explorer/water-bodies/:waterBodyName" element={<WaterBodyView />} />

                  <Route path="/data-explorer/search-sewage-report" element={<SewageSearch />} />
                  <Route path="/data-explorer/search-sewage-report/:placeId/:placeName" element={<SewageSearchPlace />} />

                  <Route path="/floodnav" element={<FloodNav />} />
                  <Route path="/floodnav/how-to" element={<FloodNavHowTo />} />

                  <Route path="/stations/:stationId" element={<StationView />} />
                  <Route path="/stations/:stationId/metric/:metricId" element={<StationView />} />
                  <Route path="/stations/:stationId/historical" element={<StationHistoricalView />} />
                  <Route path="/stations/:stationId/historical/flood/:dateTime" element={<StationHistoricalFloodView />} />
                  <Route path="/stations/:stationId/downstream" element={<StationView qualifierType="Downstream Stage" />} />
                  
                  <Route path="/flood-alerts" element={<FloodAlertsSummary />} />
                  <Route path="/flood-alerts/:floodAlertAreaId" element={<FloodAlertView />} />

                  {/* These are for the individual flood map side of things */}
                  {subdomain !== "www" && <Route path="/*" element={<FloodOverviewView />} />}
                  {subdomain !== "www" && <Route path="/map/:id" element={<FloodOverviewView />} />}
                  {subdomain === "www" && <Route path="/floodnav/:floodNavName/*" element={<FloodOverviewView />} />}
                  {subdomain === "www" && <Route path="/floodnav/:floodNavName/map/:id" element={<FloodOverviewView />} />}


                  {/* 404 Handler. look above, as !www is just sent to the FloodNav */}
                  <Route path="/*" element={<View404 />} />
                </Routes>
              </UncaughtBoundary>

              {appContext !== CONTEXTS.APP_CONTEXT_XPA ? <Row style={{fontSize: "0.6em", paddingTop: "37px", paddingBottom: "37px"}}>
                <Col xs={12}>
                  <i><b>FloodMapperUK</b> is an independent site and is not associated with any government bodies (e.g. Environment Agency).</i>
                </Col>
                <Col xs={12}>Copyright &copy; <b>FloodMapperUK</b> 2023-2024.</Col>
                <Col xs={12}>All Rights Reserved</Col>
              </Row> : null}
            </Container>
    </div>
  );
}

export default App;
