import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: require('./translations/de.json'),
      },
      en: {
        translation: require('./translations/en.json'),
      },
      es: {
        translation: require('./translations/es.json'),
      },
      fr: {
        translation: require('./translations/fr.json'),
      }
    },
    lng: 'en',  // Default language
    fallbackLng: 'en',  // Fallback language
    interpolation: {
      escapeValue: false,  // React handles XSS automatically
    },
  });

export default i18n;
